<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    :width="600"
    :title="$t('ImportGrades')"
    @ok="handleOk"
    :confirmLoading="state.confirmLoading"
  >
    <div class="type">
      <a-radio-group v-model:value="state.exportType">
        <a-radio :value="1">{{ $t("ImportByName") }}</a-radio>
        <a-radio :value="2">{{ $t("ImportByAccount") }}</a-radio>
      </a-radio-group>
    </div>
    <div>
      {{ $t("PleaseClickFirst") }}
      <!-- 请先点击 -->
      <a-button type="link" @click="downloadTemplate" style="padding: 0">{{
        $t("DownloadImportTemplate")
      }}</a-button>
      <!-- 下载导入模板 -->
      {{ $t("DownloadImportTemplateTip") }}
      <!-- ，编辑完内容后上传导入模板 -->
    </div>
    <a-upload-dragger
      v-model:fileList="state.importFileList"
      :multiple="false"
      :action="uploadUrl + 'lms/upload/importtemplate'"
      method="post"
      :headers="getHeader()"
      @change="handleChangeImport"
      :before-upload="handelBeforeUpload"
    >
      <p class="ant-upload-drag-icon">
        <file-excel-outlined />
      </p>
      <a-button class="t-mgb">
        {{
          state.importFileList.length > 0
            ? $t("train.reselect")
            : $t("train.select_file")
        }}
        <!-- "重新选择" : "选择文件" -->
      </a-button>
      <p class="ant-upload-hint">
        {{ $t("train.drop_file_attendance") }}
        <!-- 可直接将文件拖拽到此处上传，支持文件类型：xls .xlsx -->
      </p>
    </a-upload-dragger>
    <a-modal
      v-model:visible="state.showError"
      destroyOnClose="true"
      :title="$t('FailedToImportList')"
      width="1000px"
    >
      <template #footer>
        <!-- <a-button type="primary" @click="exportErrorList">
          {{ $t("DownloadErrorMessage") }}
        </a-button> -->
        <a-button key="back" @click="cancelError">
          {{ $t("CM_Cancel") }}
        </a-button>
      </template>
      <section class="error-container">
        <table>
          <thead>
            <tr>
              <template v-if="state.exportType === 1">
                <th width="130">{{ $t("Pub_Lab_UserName") }}</th>
                <th width="130">{{ $t("login.account") }}</th>
              </template>
              <template v-else>
                <th width="130">{{ $t("login.account") }}</th>
                <th width="130">{{ $t("Pub_Lab_UserName") }}</th>
              </template>
              <th width="340">{{ $t("IDNumber") }}</th>
              <th width="100">{{ $t("train.grades") }}</th>
              <th width="100">{{ $t("CM_LB_Reviews") }}</th>
              <th width="200">{{ $t("ErrorMessage") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in state.errorList" :key="index">
              <td>{{ item[0] }}</td>
              <td>{{ item[1] }}</td>
              <td>{{ item[2] }}</td>
              <td>{{ item[3] }}</td>
              <td>{{ item[4] }}</td>
              <td>{{ item[5] }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </a-modal>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, computed, getCurrentInstance } from "vue";
import {
  uploadUrl,
  getHeader,
  fileDownload,
  getCdnUrl,
  dateFormat,
} from "@/utils/tools";
import { importGrades } from "@/api/homework";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const visible = ref(false);
const emit = defineEmits(["emit"]);

const state = reactive({
  params: {},
  exportType: 1,
  importFileList: [],
  confirmLoading: false,
  showError: false,
  errorList: [],
});

function show(detail) {
  console.log(detail, '--detail--')
  state.params = {
    taskId: detail.taskId,
    detailId: [detail.detailId],
  }
  visible.value = true;
}
function cancel() {
  visible.value = false;
}
function downloadTemplate() {
  const tempName = state.exportType === 1 ? "成绩模板.xlsx" : "成绩模板v2.xlsx";
  fileDownload(
    getCdnUrl(tempName, "template"),
    $t("TypeTemplate", { type: $t("grades") })
  );
}
function handelBeforeUpload(file) {
  let fileType =
    file.type === "application/vnd.ms-excel" ||
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  if (file.type == "") {
    let type = file.name.substr(file.name.lastIndexOf(".") + 1);
    fileType = type == "xls" || type == "xlsx";
  }
  if (!fileType) {
    proxy.$message.error($t("train.wrong_file_type"));
    // 文件类型错误,请选择xls/xlsx文件
  }
  return fileType;
}
function handleChangeImport(info) {
  if (info.file.status == "done") {
    if (info.file.response.ret == 0) {
      state.importFileList = [info.file];
    } else {
      proxy.$message.error(info.file.response.msg);
      state.importFileList = [];
    }
  } else if (info.file.status != "uploading") {
    info.fileList.forEach((item, i) => {
      if (item.percent == 0) {
        info.fileList.splice(i, 1);
      }
    });
  }
}
async function handleOk() {
  if (state.importFileList.length == 0) {
    // 请上传人员模板
    proxy.$message.warn($t("train.please_upload_template_file"));
    // 请上传模板文件
    return;
  }
  let fileName = state.importFileList[0].response.data;
  state.confirmLoading = true;
  const res = await importGrades({
    ...state.params,
    file: fileName,
  });
  if (res.ret === 0) {
    proxy.$message.success(
      proxy.$t("SuccessfullyImportedNPiecesOfData", { n: res.data.success }) +
        "," +
        proxy.$t("NDataImportFailures", {
          n:
            res.data.error && res.data.error.length ? res.data.error.length : 0,
        })
    );
    if (res.data.error && res.data.error.length > 0) {
      state.errorList = res.data.error;
      state.showError = true;
    }
    res.data.success && emit("on-success");
  } else {
    proxy.$message.error(res.msg);
  }
  state.confirmLoading = false;
  visible.value = false;
  state.importFileList = [];
}
function exportErrorList() {
  let tempArray = [];
  tempArray[0] =
    state.exportType === 1
      ? ['姓名', '身份证号', '成绩', '评语', '错误信息']
      : ['账号', '姓名', '身份证号', '成绩', '评语', '错误信息'];
  state.errorList.forEach((item, index) => {
    tempArray.push(item);
  });
  let params = {};
  params.data = tempArray;
  params.name = '成绩';
  // dataToExcelExport(params, '导入成绩失败列表');
}
function cancelError() {
  state.errorList = [];
  state.showError = false;
}

defineExpose({
  show,
});
</script>
<style lang="less" scoped>
.type {
  margin-bottom: 15px;
}
</style>
